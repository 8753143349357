.searches{
    display: flex;
    margin-top: 10px;
}

.searchInputs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    height: 48px;
    border-radius: 10px;
    margin-top: 0%;
    border: 1px solid #808080;
    padding: 0 15px; /* Optional for extra spacing */
}

.searchInput input::placeholder{
    padding: 20px !important;
}

.searchIcons{
    position: absolute;
}

.add-btn button{
    height: 48px;
    font-size: 24px !important;
    margin-left: 100% !important;
    background: #F24E1E;
}

.orderDetails ul{ 
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 60px;
    
}

td.status{
    display: flex;
    gap: 15px;
    align-items: center;
}

.products-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 50px;
}
  
  .products-table th {
    background-color: #f0f2ff;
    padding: 15px;
    text-align: left;
  }
  
  .products-table td {
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .product-icon {
    width: 40px;
    height: 40px;
    background-color: #0052ff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem;
  }
  
  .closeIcon {
    font-size: 1.5rem;
    transition: opacity 0.2s ease-in-out;
  }
  
  .closeIcon:hover {
    opacity: 0.7;
  }
  
  .modal-content {
    background: white !important;
    height: 90vh;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .distributor-modal h2 {
    color: #333;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  .distributor-registration-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-row {
    display: flex;
    gap: 1rem;
    width: 100%;
  }
  
  .form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group.full-width {
    width: 100%;
  }
  
  .form-group label {
    font-size: 0.9rem;
    color: #666;
    font-weight: 500;
  }
  
  .form-row p {
    width: 100%;
    margin-bottom: 0.5rem;
    color: #666;
    font-size: 0.9rem;
  }
  
  .form-group input {
    padding: 0.75rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 0.9rem;
    outline: none;
    transition: border-color 0.2s;
    background-color: #f8f8f8;
  }
  
  .form-group input:focus {
    border-color: #2196F3;
    background-color: white;
  }
  
  .form-group input::placeholder {
    color: #aaa;
  }
  
  .submit-button {
    background-color: #FF5722;
    color: white;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background-color 0.2s;
    align-self: center;
    margin-top: 1rem;
  }
  
  .submit-button:hover {
    background-color: #F4511E;
  }
  
  .results-count {
    font-size: 0.9em;
    color: #000000;
  }
  
  .pagination .page-item.active .page-link {
    border-color: #000000;
    background-color: #fff !important;
    color: #000000 !important;
  }
 
  .pagination .page-link {
    width: 32px;
    height: 32px;
    padding: 4px 8px;
    font-size: 0.85em;
    text-align: center;
    border-radius: 5px !important;
  }
  
  .pagination-btn:disabled {
    background-color: #f9f9f9;
    color: #ccc;
    cursor: not-allowed;
  }
  