.nav-container{
    margin-top: -20px;
    margin-left: 40px;
}

.nav-links{
    margin-top: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

button{
  width: 240px;
  height: 60px;
  border-radius: 10px !important;
  background: #0024FF;
  color: white;
  font-size: 32px !important;
  border: none;
  margin-left: 40px !important;
}

.nav-links button{
  margin-top: -20px !important;
  margin-left: -0.2% !important;
}

.nav-container ul li {
    display: inline-block;
    margin-top: 20px;
    padding: 0px 15px;
    
  }

ul li{
    list-style-type: none;
}
.nav-container{
   width: 70%;
    height: 60px;
    border: 1px solid #e9e9e9;
    border-radius: 20px;
}

a{
    color: black !important;
    text-decoration: none !important;
    cursor: pointer;
    font-size: 15px;
}

.loc-search{
    width: 430px;
    height: 40px;
    margin-left: -25px;
    border-radius: 10px;
    border: 1px solid #808080;
    margin-top: -20px;
}

.nav-container input::placeholder{
  margin-left: 20px;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px){
  .nav-links{
    display: none;
  }
}