.cart-container {
    max-width: 100%;
    display: flex;
    margin: auto;
    padding: 20px;
    color: #333;
    margin-top: 50px;
    justify-content: center;
  }
  
  .cart-container h2 {
    color: #2d3b94;
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .cart-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .cart-table {
    width: 80%;
    border-collapse: collapse;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
  }
  
  .cart-table th,
  .cart-table td {
    padding: 10px;
    justify-content: space-around;
    text-align: center; /* Center align all table cells */
    font-size: 14px;
  }
  
  .cart-table thead {
    background-color: #f5f5f5;
  }
  
  .product-details {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .product-images {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 5px;
  }

  .product-mrp{
    font-size: 16px !important;
  }

  .total-price{
    font-size: 16px !important;
  }
  
  .product-info {
    display: flex;
    flex-direction: column;
  }
  
  .product-name {
    font-weight: bold;
    color: #333;
    font-size: 16px;
  }
  
  .product-category {
    color: #666;
    font-size: 12px;
  }
  
  .cart-total{
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    color: #2d3b94;
    margin-left: 10%;
  }
  
  .quantity-controls button {

    border: 1px solid #ddd;
    background-color: #A2A2A2;
    cursor: pointer;
    font-size: 12px !important;
    width: 40px; /* Adjust width for alignment */
    height: 40px;
    margin-left: 0% !important;
    text-align: center !important;
  }

  .btnControl{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .total-price {
    font-weight: bold;
    text-align: right; /* Align total price to the right if desired */
  }
  
  .cart-summary {
    width: 35%;
    height: 30%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    margin-top: 5%;
    background-color: #fff;
  }
  
  .cart-summary h3 {
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #333;
  }
  
  .cart-summary p {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    color: #666;
  }
  
  .cart-summary h4 {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    color: #2d3b94;
    margin-top: 15px;
    margin-left: -0.1%;
  }
  
  .total-amount {
    font-size: 1.2;
    margin-bottom: 20px;
    
  }
  
  .checkout-button{
    padding: 10px 20px;
    border: none;
    background-color: #0054FF;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-size: 20px !important;
    margin-top: 20px;
    width: 160px;
    height: 50px;
    margin-left: 30% !important;
  }

 /* Media Query for Mobile Devices */
 @media (max-width: 480px) {


  /* Cart container */
  .cart-container {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }

  .carItems{
    display: none;
  }

  /* Hide table headers and restructure table for mobile */
  .cart-table thead {
    display: none;
  }

  .cart-table, 
  .cart-table tbody, 
  .cart-table tr, 
  .cart-table td {
    display: block;
    width: 100%;
    border: none;
  }

  /* Style each cart item */
  .cart-table tr {
    margin-bottom: 16px;
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 12px 0;
    border-bottom: 1px solid #f3f1f1;
  }

  /* Product image */
  .product-images {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-right: 16px;
  }

  /* Product details container */
  .product-details {
    flex-grow: 1;
    padding-right: 40px; /* Space for delete button */
  }

  /* Product name and category */
  .product-name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #000;
  }

  .product-category {
    color: #888;
    font-size: 14px;
    margin-bottom: 8px;
  }

  /* Price */
  .price {
    font-weight: 500;
    margin: 8px 0;
  }

  /* Quantity controls */
  .product-name{
    font-size: 14px;
  }

  .quantity-controls button{
    width: 40px;
    height: 40px;
  }

  .btnControl{
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 50px;
    padding: 4px 8px;
    width: 40px;
  }

  .btnControl select {
    border: none;
    background: none;
    padding: 0 4px;
  }

  /* Delete button */
  .delete-button {
    position: absolute;
    top: 12px;
    right: 0;
    background: none;
    border: none;
    color: #666;
    font-size: 20px;
  }

  /* Order summary styles */
  .cart-summary {
    display: block;
    width: 100%;
    margin-top: 24px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }

  .cart-summary h3 {
    margin-bottom: 16px;
    font-size: 18px;
  }

  .cart-summary p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    color: #666;
    font-size: 16px;
  }

  .cart-summary h4 {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    padding-top: 16px;
    font-size: 16px !important;
    border-top: 1px solid #f3f1f1;
    color: #0066ff;
  }
  .total-amount{
    font-size: 16px;
    margin-bottom: 16px;
  }

  /* Checkout button */
  .checkout-button {
    width: 40%;
    height: 40px;
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 10px !important;
    font-size: 12px !important;
    font-weight: 500;
    margin-top: 16px;
    justify-content: center;
  }

  /* Hide total price column */
  .total-price {
    display: none;
  }

  /* Quantity dropdown */
  .quantity-controls select {
    padding: 4px 8px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 4px;
  }
}

@media (max-width: 376px){
  .product-name{
    font-size: 14px;
  }

  .quantity-controls button{
    width: 20px;
    height: 20px;
  }

  .btnControl{
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 50px;
    padding: 4px 8px;
    width: 25px;
  }
}

@media (max-width: 320px){
  .product-name{
    font-size: 14px;
  }

  .quantity-controls button{
    width: 20px;
    height: 20px;
  }

  .btnControl{
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 50px;
    padding: 4px 8px;
    width: 25px;
  }

  .product-images{
    width: 60px;
    height: 60px;
  }

  .product-name{
    font-size: 10px;
  }

  .product-mrp{
    font-size: 10px !important;
  }

  .total-price{
    font-size: 10px !important;
  }

  /* Order summary styles */
  .cart-summary {
    display: block;
    width: 100%;
    margin-top: 24px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }

  .cart-summary h3 {
    font-size: 18px;
  }

  .cart-summary p {
    font-size: 10px;
  }

  .cart-summary h4 {
    font-size: 10px !important;
  }
  .total-amount{
    font-size: 10px;
  }
}