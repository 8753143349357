*{
    margin: 0%;
    padding: 0%;
    font-family: "Nunito Sans";
}

.nav-1{
    height: 130px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0024FF;
}

.nav-1 p{
    font-size: 24px;
    color: white;
    line-height: 38px;
}

.nav-para{
    margin-left: 80px;
}

.logo{
    width: 200px;
    height: 80px;
}

.userLogo{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    margin-right: 80px;
}

.search-input input::placeholder{
    color: #808080;
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
    padding-left: 20px;
}

.user-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-menu a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-menu a:hover {
    background-color: #ddd;
  }
  
  .user-dropdown:hover .dropdown-menu {
    display: block;
  }
  /* Mobile navigation */
  .Mob-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #f3f1f1;
    border-top: 1px solid #f3f1f1;
    margin-top: 0px !important;
    background-color: #fff;
  }

  .Mob-nav i {
    font-size: 24px;
  }