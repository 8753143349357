.productDetailsContainer {
    display: flex;
    gap: 20px;
  }
  
  .productImageSection {
    width: 40%;
  }
  
  .mainImage {
    width: 537px;
    height: 537px;
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
    margin-left: 15px !important;
    border-radius: 10px;
  }
  
  .thumbnailGallery {
    width: 130px;
    height: 130px;
    display: flex;
    margin-left: 15px;
    gap: 40px;
    border-radius: 10px;
  }
  
  .thumbnail img {
    width: 60px;
    height: 60px;
    border: 1px solid #ddd;
  }
  
  .productInfoSection {
    width: 140%;
    background: white;
    margin-top: 20px;
    padding: 20px;
    margin-left: 10%;
    border-radius: 10px;
  }

  .productPrice {
    color: blue;
    font-size: 1.5em;
    margin: 10px 0;
  }

  .aboutProduct h4{
    margin-left: 0;
  }

  .additionalInfo h4{
    margin-left: 0;
  }
  
  .productStatsSection {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .stockInfo, .salesInfo {
    text-align: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 230px;
    height: 124px;
    font-size: 12px;
  }
  
  .stockInfo p, .salesInfo p {
    margin: 40px 0;
    font-size: 20px;
    font-weight: 800;
  }

 
  